<template>
    <section class="step-results">
        <article class="results-main">
            <div class="results">
                <h1>Bedankt</h1>
                <P class="result-thanks">
                    Dank je wel voor het invullen van deze vragenlijst!
                    Vind je het goed dat we de antwoorden die je gaf gebruiken voor verder onderzoek naar
                    bekken(bodem)klachten?
                    Daarmee kunnen we weer andere vrouwen helpen.
                    Natuurlijk doen we dat altijd zo dat niemand uit de gegevens kan opmaken dat jij het bent.
                </P>
                <YesNoInput :value.sync="yesNoConsent" title="Vind je het goed dat we je antwoorden gebruiken?"
                            id="yesNoConsent" default="Yes" :disabled="yesNoConsentControlsDisabled" @change:value="updateConsent"/>
                <div class="consentPanel">
                    <div class="message" v-if="updateConsentConfirmationMessage" v-html="updateConsentConfirmationMessage" />
                </div>

                <div class="uniqueCodePanel code-section">
                    <div v-if="uniqueCodeGenerated" class="">
                        <h2>Jouw code: <span>{{ uniqueCode }}</span></h2>
                        <p>Schrijf de code op en neem deze mee als je een afspraak maakt met een
                            bekkenfysiotherapeut. De fysiotherapeut kijkt dan na hoe jij de online vragenlijst
                            invulde.</p>
                    </div>
                </div>

                <h1 class="text-5xl">Ons advies</h1>
                <div class="result-advice" :class="advice.color" v-html="advice.text" />

                <div class="risk-factors">
                    <h2>Risico factoren</h2>
                    <div v-if="activeRiskFactorGroups.length === 0">
                        <p>Met de antwoorden die je gegeven hebt lijkt het erop dat je geen risico factoren hebt. Dit is
                            goed nieuws.</p>
                        <p>Mocht je toch klachten hebben dan kan je altijd contact opnemen met onze
                            bekkenfysiotherapeuten.</p>
                    </div>

                    <RiskFactors v-on:registerRiskFactorGroup="registerRiskFactorGroup"
                                 label="Je hebt meer kans op ongewild verlies van plas of urine als"
                                 id="riskFactorsUrineLoss" :items="[
                            {
                                riskfactor: 'red',
                                message: 'je daar al last van had in de tweede helft van je zwangerschap',
                                condition: this.$store.state.secondHalfUrineLoss === 'Yes'
                            },
                            {
                                riskfactor: 'red',
                                message: 'minstens één kind bij de geboorte 4000 gram of meer woog',
                                condition: this.$store.state.heavyBaby == 'Yes'
                            },
                            {
                                riskfactor: 'red',
                                message: 'je moeilijk naar de wc kunt, last hebt van verstopping',
                                condition: this.$store.state.blockageNow == 'Yes'
                            },
                            {
                                riskfactor: 'green',
                                message: 'je meerdere keren bevallingen hebt gehad via je schede of vagina',
                                condition: this.$store.state.givenBirthAmount > 1 && this.$store.state.givenBirthAmount-this.$store.state.cesareanAmount > 1
                            }
                         ]"/>

                    <RiskFactors v-on:registerRiskFactorGroup="registerRiskFactorGroup"
                                 label="Je hebt meer kans op ongewild verlies van poep of ontlasting als"
                                 id="riskFactorsFecesLoss" :items="[
                            {riskfactor: 'red', message: 'je daar al last van had tijdens de zwangerschap',condition: this.$store.state.unwantedFecesPregnancy == 'Yes'},
                            {riskfactor: 'orange', message: 'je gedeeltelijk of geheel uitscheurde tijdens de bevalling',condition: this.$store.state.rupture == 'Yes'},
                            {riskfactor: 'orange', message: 'je ernstig overgewicht hebt. Ofwel een zogenoemde Body Mass Index (BMI) van meer dan 30',condition: calculateBMIAfter() > 30},
                            {riskfactor: 'orange', message: 'je bij de bevalling 35 jaar of ouder was',condition: this.$store.state.age >= 35},
                            {riskfactor: 'orange', message: 'je een bevalling had met een tang of vacuümpomp (een pomp waarbij je  kindje wordt gehaald met een zuignap op het hoofdje)',condition: this.$store.state.vacuumBirth == 'Yes'}
                        ]"/>

                    <RiskFactors v-on:registerRiskFactorGroup="registerRiskFactorGroup"
                                 label="Je hebt meer kans op het ongewild laten van windjes als" id="riskFactorsGasLoss"
                                 :items="[
                            {riskfactor:'red', message: 'je gedeeltelijk of geheel uitscheurde tijdens de bevalling', condition: this.$store.state.rupture == 'Yes'},
                            {riskfactor:'red', message: 'je daar al last van had tijdens de zwangerschap. Of toen je zwanger was al waterige poep of ontlasting verloor', condition: this.$store.state.unwantedFeces == 'Yes'}
                         ]"/>

                    <RiskFactors v-on:registerRiskFactorGroup="registerRiskFactorGroup"
                                 label="Je hebt meer kans op pijn in en rond je bekken als" id="riskFactorsPelvisPain"
                                 :items="[
                            {riskfactor: 'red', message: 'je daar al last van had tijdens je zwangerschap', condition: this.$store.state.pregnancyPelvisPain == 'Yes'},
                            {riskfactor: 'red', message: 'je pijn blijft houden na de bevalling', condition: this.$store.state.pelvisPain == 'Yes'},
                            {riskfactor: 'orange', message: 'je 35 jaar of ouder was bij de bevalling', condition: this.$store.state.age >= 35},
                            {riskfactor: 'orange', message: 'je overgewicht had. Voor je zwangerschap had je een zogenoemde Body Mass Index (BMI) van 25 of hoger', condition: calculateBMIBefore() > 25},
                            {riskfactor: 'orange', message: 'je vaak al last had van lage rugklachten voordat je zwanger werd', condition: this.$store.state.prePregnancyPain == 'Yes'},
                            {riskfactor: 'orange', message: 'de bevalling was ingeleid', condition: this.$store.state.preludeLabor == 'Yes'},
                            {riskfactor: 'green', message: 'je regelmatig zwaar lichamelijk werk doet', condition: this.$store.state.heavyWork == 'Yes'}
                         ]"/>

                    <RiskFactors v-on:registerRiskFactorGroup="registerRiskFactorGroup"
                                 label="Je hebt meer kans op een verzakking als" id="riskFactorsProLabs" :items="[
                            {riskfactor: 'orange', message: 'je daar tijdens je zwangerschap al last van had', condition: this.$store.state.bumpBefore == 'Yes'}
                         ]"/>
                </div>
            </div>
            <div>
                <div class="search-doctor">
                    <h3>Maak gelijk een afspraak bij jou in de buurt</h3>
                    <p>Vul je postcode in </p>
                    <input type="text" placeholder="Bijvoorbeeld 1234 AB" id="postalCode" v-model.lazy="buildUrl">
                    <a v-bind:href="formUrl">
                        <button>
                            Vind een bekkenfysiotherapeut
                        </button>
                    </a>
                </div>
            </div>
        </article>
    </section>
</template>

<script>
import {mapState} from 'vuex';
import RiskFactors from '../FormElements/RiskFactors';
import YesNoInput from '../FormElements/YesNoInput';

export default {
    data: function () {
        return {
            age: this.$store.state.length,
            uniqueCodeGenerated: false,
            activeRiskFactorGroups: [],
            url: 'https://www.bekkenfysiotherapie.nl/klachten-rondom-zwangerschap-en-bevalling/zoek-jouw-bekkenfysiotherapeut/?postcode=',
            zipCode: '',
            formUrl: '',
            yesNoConsentControlsDisabled: false,
            updateConsentConfirmationMessage: null
        }
    },
    created() {
        this.storeSurvey();
    },
    methods: {
        async updateConsent() {
            this.updateConsentConfirmationMessage = null;
            this.yesNoConsentControlsDisabled = true;

            let response = await fetch(
                '/api.php',
                {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        action: 'update-consent',
                        consent: this.$store.state.yesNoConsent,
                        uniqueCode: this.$store.state.uniqueCode,
                        csrfToken: this.$store.state.csrfToken
                    })
                }
            );

            if (response.status === 200) {
                let result = await response.json();
                this.updateConsentConfirmationMessage = 'We hebben uw voorkeur opgeslagen.';
                this.$store.commit('updateCsrfToken', result.csrfToken);

                setTimeout(
                    () => {
                        this.updateConsentConfirmationMessage = null;
                        this.yesNoConsentControlsDisabled = false;
                    },
                    10 * 1000
                );
            }
        },
        async storeSurvey() {
            let response = await fetch(
                '/api.php',
                {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        action: 'submit-survey',
                        csrfToken: this.$store.state.csrfToken,
                        parameters: this.$store.state
                    })
                }
            );
            if (response.status === 201) {
                let result = await response.json();
                this.uniqueCodeGenerated = true;
                this.$store.commit('storeFormValue', {name: 'uniqueCode', value: result.uniqueCode});
                this.$store.commit('updateCsrfToken', result.csrfToken);
            }
        },
        isRedFlag: function () {
            return (
                this.$store.state.pelvisPain === 'Yes' ||
                this.$store.state.bumpNow === 'Yes' ||
                this.$store.state.urineLoss === 'Yes' ||
                this.$store.state.fecesLoss === "Yes" ||
                this.$store.state.blockageNow === "Yes" ||
                this.$store.state.gasLoose === "Yes"
            );
        },
        isRedFlagRupture: function () {
            return (this.$store.state.rupture === 'Yes');
        },
        isRedFlagBlockage: function () {
            return (
                this.$store.state.pregnancyPelvisPain === 'Yes' ||
                this.$store.state.heavyBaby === 'Yes' ||
                this.$store.state.secondHalfUrineLoss === 'Yes' ||
                this.$store.state.unwantedFecesPregnancy === 'Yes'
            );
        },
        isGreenFlag: function () {
            return (this.$store.state.heavyWork === 'Yes');
        },
        isOrangeFlag: function () {
            return (
                this.$store.state.age >= 35 ||
                this.$store.state.vacuumBirth === 'Yes' ||
                this.$store.state.preludeLabor === "Yes" ||
                this.$store.state.bumpBefore === 'Yes' ||
                this.$store.state.olderDelivery === 'Yes' ||
                this.$store.state.prePregnancyPain === 'Yes' ||
                this.calculateBMIBefore() > 25 ||
                this.calculateBMIAfter() > 30
            );
        },
        registerRiskFactorGroup: function (riskFactorGroupId) {
            if (riskFactorGroupId) {
                this.activeRiskFactorGroups.push(riskFactorGroupId);
            }
        },
        calculateBMIBefore: function () {
            return this.calculateBMI(this.$store.state.length, this.$store.state.weightBefore);
        },
        calculateBMIAfter: function () {
            return this.calculateBMI(this.$store.state.length, this.$store.state.weight);
        },
        calculateBMI: function (length, weight) {
            return Math.round(weight / ((length / 100) * (length / 100)));
        }
    },
    components: {
        RiskFactors,
        YesNoInput
    },
    computed: {
        ...mapState({
            uniqueCode: state => state.uniqueCode
        }),
        buildUrl: {
            get: function () {
                return this.zipCode;
            },
            set: function (value) {
                this.zipCode = value;
                this.formUrl = this.url + this.zipCode
            }
        },
        yesNoConsent: {
            get: function () {
                return this.$store.state.yesNoConsent;
            },
            set: function (value) {
                this.showConsentSubmit = (value === 'Yes');
                this.$store.commit('storeFormValue', {name: 'yesNoConsent', value: value});
            }
        },
        advice() {
            if (this.isRedFlag()) {
                return {
                    color: 'advice-red-1',
                    text: `
                        <p>
                            Uit jouw antwoorden blijkt dat je bepaalde klachten hebt in en rond het bekken. 
                            Loop daar niet mee door. Vaak kun je er zelf veel aan doen. Ons advies: zoek 
                            hulp en maak een afspraak met een bekkenfysiotherapeut bij jou in de buurt. 
                            Je krijgt altijd een behandeling die bij jou past. 
                        </p>
                    `
                };
            }

            if (this.isRedFlagRupture()) {
                return {
                    color: 'advice-red-2',
                    text: `
                        <p>
                            Uit jouw antwoorden blijkt dat de werking van je bekken(bodem) is verminderd.
                            Dat is bijna altijd zo als je geheel of gedeeltelijk uitscheurde tijdens een
                            bevalling en moest worden gehecht op de operatiekamer. Loop daar niet meer door.
                            Je kunt zelf veel doen aan het versterken van je bekken(bodem). Ons advies: maak
                            een afspraak met een bekkenfysiotherapeut bij jou in de buurt. Je krijgt altijd
                            een behandeling die bij jou past. 
                        </p>
                    `
                };
            }

            if (this.isRedFlagBlockage()) {
                return {
                    color: 'advice-red-3',
                    text: `
                        <p>
                            Uit jouw antwoorden blijkt dat je vooral in de rode groep valt. Dat wil zeggen dat
                            je een grotere kans hebt op het krijgen van klachten in en rond het bekken.
                            Je kunt zelf veel doen om die klachten te voorkomen. Ons advies: laat onderzoeken
                            hoe je bekken(bodem) nu werkt. Maak een afspraak voor een bekken(bodem)check bij
                            een bekkenfysiotherapeut bij jou in de buurt.  
                        </p>
                        <p>
                            Wil je geen afspraak maken? Dan raden we je aan om wel alvast zelf met oefeningen 
                            te beginnen. Je kunt deze vinden op  <a href="https://www.bekkenfysiotherapie.nl/oefeningen">onze website</a>. 
                            Daarnaast is het verstandig om op <a href="https://www.bekkenfysiotherapie.nl/hersteltijd">onze site</a> 
                            na te lezen hoelang het meestal duurt voor je bekken en bekkenbodem herstellen na een bevalling. 
                            Mocht je over enige tijd toch klachten krijgen in en rond je bekken, dan kun je daar alsnog iets 
                            aan doen. Een bekkenfysiotherapeut kan je daarbij helpen.
                        </p>
                    `
                };
            }

            if (this.isOrangeFlag()) {
                return {
                    color: 'advice-orange',
                    text: `
                        <p>
                            Uit jouw antwoorden blijkt dat je vooral in de oranje groep valt. Dat betekent dat je meer kans hebt
                            op het krijgen van klachten in en rond je bekken. Je kunt zelf veel doen om die te voorkomen.
                            Ons advies: maak een afspraak voor een bekken(bodem)check bij een bekkenfysiotherapeut bij jou in de buurt. 
                        </p>
                        <p>
                            Wil je geen afspraak maken? Dan raden we je aan om wel alvast bepaalde
                            oefeningen te doen. Je kunt deze vinden op <a href="https://www.bekkenfysiotherapie.nl/oefeningen">onze
                            website</a>. Daarnaast is het verstandig om op <a href="https://www.bekkenfysiotherapie.nl/hersteltijd">onze site</a>
                            te lezen hoelang het meestal duurt voor je bekken
                            en bekkenbodem herstellen na een bevalling. Mocht je over enige tijd toch klachten
                            krijgen in en rond je bekken, dan kun je natuurlijk altijd alsnog een afspraak maken
                            met een bekkenfysiotherapeut.
                        </p>
                    `
                };
            }

            return {
                color: 'advice-green',
                text: `
                    <p>
                        Uit jouw antwoorden blijkt dat je vooral in de groene groep valt. Dat betekent dat je een licht verhoogde kans hebt 
                        op het krijgen van klachten in en rond het bekken. Je kunt zelf veel doen om klachten te voorkomen.  
                        Lees op <a href="https://www.bekkenfysiotherapie.nl/waarmoetikopletten">onze
                        website</a> waarop je moet letten, hoe je na de bevalling je bekken(bodem) <a href="https://www.bekkenfysiotherapie.nl/stapvoorstap">stapje
                        voor stapje</a> weer sterker kunt maken en welke <a href="https://www.bekkenfysiotherapie.nl/oefeningen">oefeningen</a> je kunnen helpen.
                    </p>
                    <p>
                        Wil je meer weten? Bijvoorbeeld over hoe je de oefeningen precies moet doen of over
                        de licht verhoogde kans die jij hebt op bekken(bodem)klachten? Maak een afspraak
                        met een bekkenfysiotherapeut bij jou in de buurt
                    </p>
                `
            };
        }
    }
}
</script>

<style>
.confirm {
    @apply bg-primary-500;
    @apply text-white;
    @apply p-4;
    @apply rounded-sm;
    @apply mx-auto;
    @apply shadow-md;
}

.code-section h2,
.code-section h2 span,
.code-section p {
    @apply text-primary-500;
    @apply font-bold;
}

.code-section h2 {
    @apply font-light;
    @apply text-6xl;
    @apply leading-none;
    @apply mb-2;
}

.step-results h1 {
    @apply text-secondary-500;
}

.step-results .result-advice p {
    @apply mb-4;
}

.result-thanks {
    font-size: large;
    line-height: 25px;
}

.code-section {
    padding-top: 5px;

    h2 {
        @apply mt-0;
    }
}

.consentPanel {
    @apply mt-4 mb-0 min-h-20 block;

    .message {
        @apply border border-primary-500
            p-4
            rounded
            bg-gray-200;
    }
}

.risk-factors h2 {
    @apply text-secondary-500;
    @apply mb-2;
    font-size: 2.15rem;
}

.risk-factors h3 {
    @apply mb-3;
    @apply mt-3;
}

.results-main {
    @apply flex;
    @apply flex-col;
}

.step-results {
    @apply w-3/4;
    max-width: 100%;
}

.step-results {
    @apply w-full;
}

.results {
    @apply w-full;
}

.results a {
    @apply text-primary-500;
    @apply underline;
}

.results h1 {
    @apply mb-0;
    @apply pb-2;
}

.search-doctor {
    @apply w-full;
    @apply pt-12;
    @apply bg-primary-500;
    @apply p-4;
    @apply h-64;
    @apply mt-12;
    @apply rounded-sm;
}

.search-doctor h3 {
    @apply text-2xl;
    @apply font-bold;
    @apply text-white;
    @apply pb-4;
    @apply leading-tight;
}

.search-doctor p {
    @apply font-semibold;
    @apply text-white;
    @apply text-sm;
}

.search-doctor input[type=text] {
    @apply bg-transparent;
    @apply border-b;
    @apply border-white;
    @apply text-white;
    @apply text-xl;
}

.search-doctor input[type=text]:focus {
    @apply outline-none;
}

.search-doctor button {
    @apply bg-white;
    @apply text-primary-500;
    @apply p-4;
    @apply outline-none;
    @apply border-none;
    @apply rounded;
    @apply text-lg;
    @apply font-semibold;
    @apply mt-6;
    @apply w-full;
}

.search-doctor button:hover {
    @apply bg-gray-300;
}


@screen sm {
    .search-doctor {
        width: 313px;

    }
}

@screen md {
    .results {
        @apply w-3/4;
        @apply mx-auto;
    }

    .search-doctor {
        width: 313px;
        @apply ml-24;
        @apply h-64;
    }
}

@screen lg {
    .results {
        @apply w-full;
        @apply mx-auto;
    }

    .search-doctor {
        width: 313px;
        @apply ml-0;
        @apply h-64;
    }
}

@screen xl {
    .results-main {
        @apply flex-row;
        @apply flex;
    }

    .search-doctor {
        width: 313px;
        @apply ml-24;
        @apply h-64;
    }

    .results {
        @apply w-3/5;
    }
}
</style>
