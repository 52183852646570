export const validationMixin = {
    data() {
        return {
            validationComponent: {
                owner: String,
                isDirty: false,
                isValid: false,
                message: String,
                parent: String
            }
        }
    },
    created() {
        this.validationComponent = {owner: this.id, isDirty: false, isValid: false, parent: this.step};
        this.$store.commit('validation/registerFormComponent', this.validationComponent);
    },
    destroyed() {
        this.$store.commit('validation/unRegisterFormComponent', this.validationComponent);
    },
    methods: {
        isValueValid: function () {
            return (typeof this.internalValue !== 'undefined' && this.internalValue.length > 0);
        },
        validateComponent: function () {
            this.validationComponent.isValid = false;

            if (this.isValueValid()) {
                this.validationComponent.isValid = true
            }

            this.$store.commit('validation/updateFormComponent', this.validationComponent);

            this.errorMessages.currentMessage = '';
            if (!this.validationComponent.isValid && this.validationComponent.isDirty) {
                this.errorMessages.currentMessage = this.errorMessages.messages[0];
            }
        }
    }
};
