require('es6-promise').polyfill();
import 'whatwg-fetch';

import "@/assets/css/utilities/reset.css";

import "@/assets/css/tailwind.css";

import "@/assets/css/icons.css";
import "@/assets/css/components/ButtonIcon.css";
import "@/assets/css/components/FormElements.css";
import "@/assets/css/fonts.css";
import "@/assets/css/typography.css";

import VueRouter from 'vue-router';
import Vuex from 'vuex';
import Vue from 'vue';
import store from './store/index';

import Home from './components/FormSteps/Start';
import Step1 from './components/FormSteps/Step1';
import Step2 from './components/FormSteps/Step2';
import Step3 from './components/FormSteps/Step3';
import Step4 from './components/FormSteps/Step4';
import Step5 from './components/FormSteps/Step5';
import Step6 from './components/FormSteps/Step6';
import Results from './components/FormSteps/Results';

import App from './components/App.vue'

Vue.use(Vuex);
Vue.config.productionTip = false;

const routes = [
    {name: 'home', path: '/', component: Home},
    {name: 'step1', path: '/stap1', component: Step1},
    {name: 'step2', path: '/stap2', component: Step2},
    {name: 'step3', path: '/stap3', component: Step3},
    {name: 'step4', path: '/stap4', component: Step4},
    {name: 'step5', path: '/stap5', component: Step5},
    {name: 'step6', path: '/stap6', component: Step6},
    {name: 'results', path: '/results', component: Results}
];

const router = new VueRouter({
    base: window.location.pathname,
    routes
});

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
});
