<template>
    <div class="form-element yes-no-input-element" v-bind:class="{'border-alert': errorMessages.currentMessage}">
        <div class="question" v-html="title"></div>
        <div class="yes-no-input-group" :class="{disabled: disabled}">
            <input v-model="localModel" :id="id + '-Yes'" type="radio" :name="id" value="Yes" :disabled="disabled" />
            <label :for="id + '-Yes'">Ja</label>
            <input v-model="localModel" :id="id + '-No'" type="radio" :name="id" value="No" :disabled="disabled" />
            <label :for="id + '-No'">Nee</label>
        </div>
        <p v-if="errorMessages.currentMessage" class="error-message">{{ errorMessages.currentMessage }}</p>
    </div>
</template>

<script>
import {validationMixin} from "../mixins/ValidationMixin";

export default {
    props: {
        'title': String,
        'id': String,
        'value': String,
        'step': Number,
        'default': {
            type: String,
            default: ''
        },
        'disabled': {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            internalValue: String,
            errorMessages: {
                currentMessage: '',
                messages: ['Je moet hier nog een keuze opgeven']
            }
        }
    },
    created() {
        this.internalValue = this.value ? this.value : this.default;
        if (typeof this.default !== 'undefined' && this.default.length > 0) {
            this.$emit('update:value', this.internalValue);
        }
        this.validateComponent();
    },
    computed: {
        localModel: {
            get: function () {
                return this.internalValue;
            },
            set: function (value) {
                if (!this.disabled) {
                    this.internalValue = value;
                    this.validationComponent.isDirty = true;
                    this.$emit('update:value', this.internalValue);
                    this.$emit('change:value', this.internalValue);
                    this.validateComponent();
                }
            }
        }
    },
    mixins: [validationMixin]
}
</script>

<style>
.yes-no-input-element input[type="radio"] {
    @apply hidden;
}

.yes-no-input-element .yes-no-input-group {
    @apply border-2;
    @apply border-solid;
    @apply rounded;
    @apply flex;
    @apply flex-row;
    @apply items-start;
    @apply pt-3;
    @apply pb-3;
    @apply px-3;
}

.yes-no-input-element label {
    @apply relative;
    line-height: 1.75rem;
    @apply px-8;
}

.yes-no-input-element input[type="radio"]:checked + label {
    @apply font-bold;
}

.yes-no-input-group label:before {
    @apply bg-white;
    @apply border-2;
    @apply border-gray-300;
    @apply border-solid;
    @apply rounded-full;
    @apply w-6;
    @apply h-6;
    @apply absolute;

    content: "";
    font-size: 0;
    left: 0;
    top: 0;
    transition: all 125ms cubic-bezier(.96, .11, .71, 1);
}

.yes-no-input-group input[type="radio"]:checked + label:before {
    @apply border-2;
    @apply border-primary-500;
    @apply w-6;
    @apply h-6;
    @apply bg-white;
}

.yes-no-input-group label:after {
    @apply bg-primary-500;
    @apply rounded-full;
    @apply absolute;

    transition: all 125ms cubic-bezier(1, .71, .11, .96);
    content: "";
    width: 0;
    height: 0;
    left: 0.75rem;
    top: 0.75rem;
    transform: translate(25%, 25%);
}

.yes-no-input-group.disabled label {
    @apply text-gray-600;

}

.yes-no-input-group.disabled input[type="radio"]:checked + label:before {
    @apply border-gray-600;
}

.yes-no-input-group.disabled label:after {
    @apply bg-gray-600;
}

.yes-no-input-group input[type="radio"]:checked + label:after {
    @apply w-3;
    @apply h-3;
    left: 0;
    top: 0;
    transform: translate(50%, 50%);
}
</style>
