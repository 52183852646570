<template>
    <section>
        <article>
            <h1 class="text-primary">Stap 5: Pijn in en/of rond het bekken</h1>

            <p>Tijdens de zwangerschap en/of na je bevalling kun je pijn voelen
                in en/of rond je bekken. Je hebt bijvoorbeeld pijn in je
                onderrug. Of in je schaambeen, dan voel je pijn in en rond je
                schede. Ook kun je zere liezen hebben, links en/of rechts op de
                plek waar je bovenbenen eindigen en je onderbuik begint. Of je
                stuit doet pijn en daardoor kun je moeilijk zitten. Verder kun
                je last krijgen van je bekkenbodem, de onderkant van je bekken. 
            </p>

            <YesNoInput :step="stepNr" :value.sync="pelvisPain" title="Herken je een of meerdere van deze klachten? Dus heb je pijn in en/of rond je bekken?"
                        id="pelvisPain"/>

            <Scale v-if="displayPanel" :step="stepNr" :value.sync="feelingScale" title="Zo ja. Kijk terug op de afgelopen week en geef dan pas antwoord op de volgende vraag.
                Hoe voelde je je? Kun je door pijn in en/of rond je bekken bepaalde dingen
                niet meer doen? Beperkt het je dagelijks leven?" id="feelingScale"/>
        </article>
    </section>
</template>

<script>
import YesNoInput from '../FormElements/YesNoInput';
import Scale from '../FormElements/Scale';

export default {
    data() {
        return {
            stepNr: 5,
            displayPanel: (this.$store.state.pelvisPain === 'Yes')
        }
    },
    computed: {
        pelvisPain: {
            get: function () {
                return this.$store.state.pelvisPain;
            },
            set: function (value) {
                this.displayPanel = (value === 'Yes');
                this.$store.commit('storeFormValue', {name: 'pelvisPain', value: value});
            }
        },
        feelingScale: {
            get: function () {
                return this.$store.state.feelingScale;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'feelingScale', value: value});
            }
        }
    },
    components: {
        YesNoInput,
        Scale
    }
}
</script>
