<template>
    <div class="form-element radio-options-input-element" v-bind:class="{'border-alert': errorMessages.currentMessage}">
        <div class="question" v-html="label"></div>
        <div class="radio-input-group">
            <div v-for="(item, index) in items" :key="index" class="radio-input-item">
                <input type="radio" :name="id" :id="id + index" v-model="localModel" :value="item">
                <label :for="id + index" v-html="item"></label>
            </div>
        </div>
        <p v-if="errorMessages.currentMessage" class="error-message">{{ errorMessages.currentMessage }}</p>
    </div>
</template>

<script>
import {validationMixin} from "../mixins/ValidationMixin";

export default {
    props: {
        'label': String,
        'value': String,
        'id': String,
        'items': Array,
        'step': Number
    },
    data() {
        return {
            internalValue: String,
            errorMessages: {
                currentMessage: '',
                messages: [
                    'Je moet hier nog een keuze opgeven'
                ]
            }
        }
    },
    created() {
        this.internalValue = (this.value ? this.value : '');
        this.validateComponent();
    },
    computed: {
        localModel: {
            get: function () {
                return this.internalValue;
            },
            set: function (value) {
                this.internalValue = value;
                this.validationComponent.isDirty = true;
                this.$emit('update:value', value);
                this.validateComponent();
            }
        }
    },
    mixins: [validationMixin]
}
</script>

<style>
.radio-input-group {
    @apply border-2;
    @apply border-solid;
    @apply rounded;
    @apply flex;
    @apply flex-col;
    @apply items-start;
    @apply mt-2;
    @apply pt-3;
    @apply pb-3;
    @apply px-3;
}

.radio-options-input-element input[type="radio"] {
    @apply hidden;
}

.radio-input-item {
    @apply relative;
    margin: 0 0 1rem;
    padding: 0 0 0 2rem;
}

.radio-input-item:last-of-type {
    margin: 0;
}

.radio-input-item label {
    line-height: 1.75rem;
}

.radio-options-input-element input[type="radio"]:checked + label {
    @apply font-bold;
}

.radio-options-input-element .radio-input-item label:before {
    @apply bg-white;
    @apply border-2;
    @apply border-gray-300;
    @apply border-solid;
    @apply rounded-full;
    @apply w-6;
    @apply h-6;
    @apply absolute;

    content: "";
    font-size: 0;
    left: 0;
    top: 0;
    transition: all 125ms cubic-bezier(.96, .11, .71, 1);
}

.radio-options-input-element input[type="radio"]:checked + label:before {
    @apply border-2;
    @apply border-primary-500;
    @apply w-6;
    @apply h-6;
    @apply bg-white;
}

.radio-options-input-element .radio-input-item label:after {
    @apply bg-primary-500;
    @apply rounded-full;
    @apply absolute;

    transition: all 125ms cubic-bezier(1, .71, .11, .96);
    content: "";
    width: 0;
    height: 0;
    left: 0.75rem;
    top: 0.75rem;
    transform: translate(25%, 25%);
}

.radio-options-input-element input[type="radio"]:checked + label:after {
    @apply w-3;
    @apply h-3;
    left: 0;
    top: 0;
    transform: translate(50%, 50%);
}
</style>
