<template>
    <section>
        <article>
            <h1>Stap 4: Gevoel dat je schede of vagina is verzakt</h1>

            <p>Je kunt tijdens je zwangerschap, maar ook na de bevalling, last 
                hebben van een zogenoemd vaginaal verzakkingsgevoel. Dan zie je
                een bal bij je schede en/of heb je het gevoel dat je op een bal
                zit. Het kan ook zijn dat het van onderen zwaar aanvoelt. 
            </p>

            <YesNoInput :step="stepNr" :value.sync="bumpBefore"
                        title="Voelde je of zag je een bobbel bij je schede of vagina tijdens je zwangerschap?"
                        id="bumpBefore"/>
            <YesNoInput :step="stepNr" :value.sync="bumpNow"
                        title="Voel of zie je nu een bobbel bij je schede of vagina?" id="bumpNow"/>
            <YesNoInput :step="stepNr" :value.sync="heavyWork"
                        title="Doe je vaak zwaar lichamelijk werk? Moet je bijvoorbeeld veel tillen of bukken? Of sta je lang achter elkaar"
                        id="heavyWork"/>

        </article>
    </section>
</template>

<script>
import YesNoInput from '../FormElements/YesNoInput';

export default {
    data() {
        return {
            stepNr: 4
        }
    },
    computed: {
        bumpBefore: {
            get: function () {
                return this.$store.state.bumpBefore;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'bumpBefore', value: value});
            }
        },
        bumpNow: {
            get: function () {
                return this.$store.state.bumpNow;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'bumpNow', value: value});
            }
        },
        heavyWork: {
            get: function () {
                return this.$store.state.heavyWork;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'heavyWork', value: value});
            }
        }
    },
    components: {
        YesNoInput
    }
}
</script>
