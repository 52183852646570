var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('article',[_c('h1',[_vm._v("Stap 2: Verlies van plas of urine")]),_c('p',[_vm._v(" Veel vrouwen kunnen af en toe hun plas of urine niet ophouden. Heb jij daar ook weleens last van? ")]),_c('YesNoInput',{attrs:{"step":_vm.stepNr,"value":_vm.urineLoss,"title":"Verlies je weleens plas of urine zonder dat je het wilt?","id":"urineLoss"},on:{"update:value":function($event){_vm.urineLoss=$event}}}),(_vm.showUrineLossPanel)?_c('div',[_c('p',{staticClass:"mt-8"},[_vm._v(" Dank je wel dat je antwoord wilde geven op deze vraag. Als je ‘ja’ invulde, willen we graag nog meer van je weten. We stellen je extra vragen over het niet kunnen ophouden van je plas in de afgelopen vier weken. ")]),_c('RadioOptionsInput',{attrs:{"step":_vm.stepNr,"value":_vm.urineLossOccurance,"label":"Hoe vaak kun je je plas niet ophouden?","id":"urineLossOccurance","items":['Een keer per week of minder dan een keer per week',
                    'Twee tot drie keer per week',
                    'Een keer per dag',
                    'Op elk moment']},on:{"update:value":function($event){_vm.urineLossOccurance=$event}}}),_c('RadioOptionsInput',{attrs:{"step":_vm.stepNr,"value":_vm.urineLossAmount,"label":"Hoeveel plas verlies je meestal?","id":"urineLossAmount","items":['Een beetje',
                        'Redelijk wat',
                        'Echt veel']},on:{"update:value":function($event){_vm.urineLossAmount=$event}}}),_c('Scale',{attrs:{"step":_vm.stepNr,"title":"Heb je er last van in je dagelijks leven dat je zonder dat je het wilt plas verliest?","value":_vm.urineLossInfluence,"id":"urineLossInfluence"},on:{"update:value":function($event){_vm.urineLossInfluence=$event}}}),_c('CheckBoxesInput',{attrs:{"step":_vm.stepNr,"value":_vm.urineLossHow,"label":"Wanneer verlies je plas? Je mag meerdere antwoorden geven.","id":"urineLossHow","items":['Ik verlies plas voordat ik het toilet kan halen',
                        'Ik verlies plas als ik hoest of nies',
                        'Ik verlies plas als ik slaap',
                        'Ik verlies plas als ik sport of op een andere manier me inspan',
                        'Ik verlies plas als ik na het plassen weer mijn kleren aantrek',
                        'Ik verlies plas, maar ik weet niet waarom',
                        'Ik verlies op elk moment plas']},on:{"update:value":function($event){_vm.urineLossHow=$event}}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }