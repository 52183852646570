<template>
    <section>
        <header>
            <figure>
                <img src="../../src/assets/images/logo-nvfb.png" alt="Logo van het NVFB"/>
            </figure>
            <Breadcrumb/>
        </header>

        <main>
            <Component v-bind:is="currentStep"/>
        </main>

        <footer>
            <StepNav :currentStepNumber="currentStepNumber" v-on:stepChange="onStepChange"/>
        </footer>
    </section>
</template>

<script>
import Breadcrumb from './Navigation/Breadcrumb';
import StepNav from './Navigation/StepNav';
import Start from './FormSteps/Start';
import Step1 from './FormSteps/Step1';
import Step2 from './FormSteps/Step2';
import Step3 from './FormSteps/Step3';
import Step4 from './FormSteps/Step4';
import Step5 from './FormSteps/Step5';
import Step6 from './FormSteps/Step6';
import Results from './FormSteps/Results';

export default {
    components: {
        Breadcrumb,
        StepNav
    },
    computed: {
        currentStep() {
            if (this.currentStepNumber === 7) {
                return Results;
            }
            if (this.currentStepNumber === 6) {
                return Step6;
            }
            if (this.currentStepNumber === 5) {
                return Step5;
            }
            if (this.currentStepNumber === 4) {
                return Step4;
            }
            if (this.currentStepNumber === 3) {
                return Step3;
            }
            if (this.currentStepNumber === 2) {
                return Step2;
            }
            if (this.currentStepNumber === 1) {
                return Step1;
            }

            return Start;
        }
    },
    data() {
        return {
            currentStepNumber: 0
        }
    },
    methods: {
        onStepChange: function (value) {
            this.currentStepNumber = value;
        }
    }
}
</script>

<style>
body {
    overflow-y: scroll;
}

header {
    @apply bg-white;
    @apply flex;
    @apply items-center;
    @apply m-0;
    @apply p-0;
    @apply h-40;
}

header figure {
    @apply mx-auto;
}

header figure img {
    @apply mx-auto;
    @apply pr-2;
}

main {
    @apply w-11/12;
    @apply mx-auto;
    @apply mb-40;
    @apply py-2;
}

section {
    @apply mx-auto;
}

main > section {
    max-width: 600px;
}

article p {
    @apply leading-normal;
}

footer nav {
    @apply fixed;
    @apply bottom-0;
    @apply shadow-md;
    @apply w-full;
    @apply flex;
    @apply flex-row;
    @apply items-center;
    @apply justify-center;
    @apply py-5;
}

@screen md {
    header figure img {
        @apply mx-auto;
        padding-right: 0px;
    }

    header figure {
        @apply w-2/3;
    }
}

@screen lg {
    main {
        @apply w-1/2;
    }

    header {
        @apply h-24;
    }

    header figure {
        @apply w-1/4;
        @apply h-full;
        @apply px-6;
    }

    header figure img {
        max-height: 100%;
        width: auto;
        @apply pt-6;
    }

    section {
        @apply mx-0;
    }
}
</style>
