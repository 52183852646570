<template>
    <nav class="form-step-navigation" v-if="currentStepNumber < 7">
        <div v-if="currentStepNumber == 0" class="form-step-navigation-start">
            <button class="button start-first-step" @click="goToNextStep">Start de Bekken(bodem)check</button>
        </div>
        <div v-if="currentStepNumber > 0 " class="form-step-navigation-steps">
            <a href="#" v-on:click="goToPreviousStep" class="button goToPreviousStep">
                <span>Vorige</span>
            </a>
            <ul class="step-indicators list-reset">
                <li v-for="(step, index, n) in steps"
                    :key="index"
                    :class="[{active : n+1 === currentStepNumber, invalid: !isStepValid(n+1) && isStepVisited(n+1), 'valid': isStepValid(n+1) && isStepVisited(n+1)}, isStepVisited(n+1) ? 'visited' : 'not-visited']">
                    <span>{{ index }}</span>
                </li>
            </ul>
            <a href="#" v-on:click="goToNextStep" class="button goToNextStep" :class="nextButtonClass">
                <span>Volgende</span>
            </a>
        </div>
    </nav>
</template>

<script>
import {mapState} from 'vuex';

export default {
    props: {currentStepNumber: Number},
    data() {
        return {
            internalCurrentStepNumber: 0,
            steps: {
                1: {
                    visited: false
                },
                2: {
                    visited: false
                },
                3: {
                    visited: false
                },
                4: {
                    visited: false
                },
                5: {
                    visited: false
                },
                6: {
                    visited: false
                }
            }
        }
    },
    mounted: function () {
        this.internalCurrentStepNumber = this.currentStepNumber;
    },
    watch: {
        formLastUpdated() {
            this.$store.dispatch('validation/actionValidateStep', {stepNr: this.internalCurrentStepNumber});
        }
    },
    methods: {
        goToPreviousStep: function () {
            this.internalCurrentStepNumber--;
            this.performStepChange();
        },
        goToNextStep: function () {
            if (this.isStepValid(this.internalCurrentStepNumber)) {
                this.internalCurrentStepNumber++;
                this.performStepChange();
            }
        },
        goToStep: function (value) {
            if (this.isStepValid(this.internalCurrentStepNumber)) {
                this.internalCurrentStepNumber = value;
                this.performStepChange();
            }
        },
        isStepVisited: function (stepNr) {
            return this.steps[stepNr].visited;
        },
        isStepValid(stepNr) {
            if (stepNr > 0 && stepNr < 7) {
                this.$store.dispatch('validation/actionValidateStep', {stepNr: stepNr});
                return this.$store.state.validation.steps[stepNr];
            }
            if (stepNr === 0 || stepNr === 7) {
                return true;
            }
        },
        performStepChange() {
            if (this.internalCurrentStepNumber > 0 && this.internalCurrentStepNumber < 7) {
                this.steps[this.internalCurrentStepNumber].visited = true;
            }
            this.$emit('stepChange', this.internalCurrentStepNumber);
        }
    },
    computed: {
        ...mapState('validation', ['formLastUpdated']),
        nextButtonClass() {
            return this.isStepValid(this.internalCurrentStepNumber) ? 'nextStepIsValid' : 'nextStepIsInValid';
        }
    }
}
</script>

<style>
footer nav {
    @apply bg-gray-200;
}

.form-step-navigation {
    @apply fixed;
    @apply bottom-0;
    @apply py-5;
    @apply flex-no-wrap;
    @apply shadow-upper;
    min-height: 7.8125rem;
}

.form-step-navigation-start,
.form-step-navigation-steps {
    @apply w-full;
    @apply flex;
    @apply flex-row;
    @apply flex-wrap;
    @apply items-center;
    @apply justify-center;
}

.form-step-navigation .button {
    @apply no-underline;
    @apply font-semibold;
    @apply text-lg;
    min-height: 3.25rem;
    min-width: 18rem;

}

.button.start-first-step {
    @apply shadow-upper;
    @apply border-0;
    @apply rounded-sm;
    @apply bg-primary-500;
    @apply text-white;
    @apply text-sm;
    @apply font-thin;
}

.button.goToPreviousStep,
.button.goToNextStep {
    @apply mx-2;
    @apply shadow-upper;
    @apply relative;
    flex-basis: 40%;
    order: 2;
    max-width: 10rem;
    min-width: 10rem;
    @apply rounded-sm;
}


.button.goToPreviousStep span,
.button.goToNextStep span {
    @apply block;
    padding: 0 0 0 3rem;
    line-height: 3rem;
}

.button.goToNextStep span {
    padding: 0 0 0 2.5rem;
}


.button.goToPreviousStep:before,
.button.goToNextStep:before {
    @apply h-full;
    @apply absolute;
    @apply inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    content: '';
    left: 0.5rem;
    right: .75em;
    top: 0;
    width: 1.5em;
}

.button.goToNextStep:before {
    background-image: url('../../../src/assets/images/icons/baseline-arrow_forward-24px.svg');
    right: 0.5rem;
    left: auto;
}

.button.goToPreviousStep:before {
    background-image: url('../../../src/assets/images/icons/baseline-arrow_back-24px.svg');
}

.button.goToNextStep {
    @apply bg-primary-500;
    @apply text-white;
}

.button.goToPreviousStep {
    @apply bg-white;
    @apply text-primary-500;
}

.button.nextStepIsInValid {
    @apply bg-gray-900;
    @apply opacity-50;
    @apply text-white;
    @apply cursor-not-allowed;
}

.step-indicators {
    @apply flex;
    @apply flex-row;
    @apply items-center;
    @apply justify-center;
    @apply mx-8;
    @apply mb-6;
    order: 1;
    flex-basis: 100%;
    width: 100%;
}

.step-indicators li {
    @apply bg-gray-400;
    @apply h-6;
    @apply w-6;
    @apply text-xs;
    @apply relative;
    @apply text-center;
    @apply cursor-default;

    border-radius: 50%;
    line-height: 1.5rem;
    margin-right: 1.5rem;
    z-index: 1;
}

.step-indicators li.valid {
    @apply bg-primary-500;
}

.step-indicators li.invalid {
    @apply bg-secondary-500;
}

.step-indicators li.active {
    @apply bg-accent-500;
    @apply font-bold;
}

.step-indicators li:last-of-type {
    margin-right: 0;
}

.step-indicators li:first-of-type::before {
    @apply hidden;
}

.step-indicators li::before {
    @apply bg-gray-400;
    @apply w-6;
    @apply absolute;
    content: "";
    height: 3px;
    right: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    z-index: 0;
}

.step-indicators li.visited.invalid::before,
.step-indicators li.visited.valid::before {
    @apply bg-primary-500;
}

.step-indicators li span {
    @apply text-gray-800;
    @apply flex;
    @apply justify-center;
    @apply no-underline;
}

.step-indicators li.active span,
.step-indicators li.valid span,
.step-indicators li.invalid span {
    @apply text-white;
}

@screen md {
    .form-step-navigation-steps {
        @apply flex-no-wrap;
    }

    .goToPreviousStep,
    .goToNextStep {
        flex-basis: auto;
    }

    .goToPreviousStep {
        order: 1;
    }

    .goToNextStep {
        order: 3;
    }

    .step-indicators {
        @apply mx-6;
        @apply mb-0;
        @apply w-auto;
        order: 2;
        flex-basis: auto;
    }

    .step-indicators li {
        @apply h-10;
        @apply w-10;
        @apply text-base;

        line-height: 2.5rem;
        margin-right: 2.5rem;
    }

    .step-indicators li::before {
        @apply w-10;
    }
}
</style>
