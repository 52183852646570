<template>
    <div class="form-element text-input-element" v-bind:class="{'border-alert': errorMessages.currentMessage}">
        <div class="question" v-html="title"></div>
        <div class="scale-input-group">
            <div class="scale-labels-min-max">
                <div class="label-min">Helemaal niet</div>
                <div class="label-max">Heel erg</div>
            </div>

            <div class="scale-ticks">
                <span v-for="(number, i) in 11" v-bind:key="i" class="scale-tick-item">
                    <input v-model="localModel" type="radio" :name="id" :id="id + number" :value="i">
                    <label :for="id + number">
                        {{ i }}
                    </label>
                </span>
            </div>
            <div class="label-max-phone">Heel erg</div>
        </div>
        <p v-if="errorMessages.currentMessage" class="error-message">{{ errorMessages.currentMessage }}</p>
    </div>
</template>

<script>
import {validationMixin} from '../mixins/ValidationMixin';

export default {
    props: {
        'title': String,
        'id': String,
        'value': Number,
        'step': Number
    },
    data() {
        return {
            internalValue: Number,
            errorMessages: {
                currentMessage: '',
                messages: [
                    'Je moet hier nog een keuze opgeven.'
                ]
            }
        }
    },
    created() {
        this.internalValue = (typeof this.value !== 'undefined' && this.value > -1) ? this.value : -1;
        this.validateComponent();
    },
    methods: {
        isValueValid: function () {
            return (this.internalValue > -1);
        }
    },
    computed: {
        localModel: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.internalValue = value;
                this.validationComponent.isDirty = true;
                this.$emit('update:value', this.internalValue);
                this.validateComponent();
            }
        }
    },
    mixins: [validationMixin]
}
</script>

<style>
.scale-input-group {
    @apply border-2;
    @apply border-solid;
    @apply rounded;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply mt-2;
    @apply pt-3;
    @apply pb-3;
    @apply px-3;
}

.label-max {
    @apply hidden;
}

.label-min {
    padding-bottom: 30px;
}

.scale-labels-min-max {
    @apply w-full;
}

.label-max-phone {
    @apply w-full;
    padding-top: 20px;
}

input[type=range] {
    @apply w-full;
    @apply outline-none;
    @apply m-0;
}

.scale-ticks {
    @apply flex;
    @apply flex-col;
    @apply mt-2;
    @apply pl-8;
    @apply pr-3;
    @apply w-full;
    @apply -mb-6;
}

.scale-ticks span {
    @apply flex;
    @apply justify-center;
    @apply w-7;
    @apply inline-block;
    @apply border border-transparent;
    @apply text-center;

}

.scale-ticks input[type="radio"] {
    @apply hidden;
}

.scale-tick-item {
    @apply relative;
    @apply mb-4;
    @apply -mt-4;
    @apply pt-2;
}

.scale-tick-item label {
    @apply inline;
    @apply pb-2;
    @apply float-right;
    @apply -mt-4;
    @apply text-right;
    width: 50px;
    line-height: 1.75rem;
    cursor: pointer;
    position: relative;
}

.scale-tick-item input[type="radio"]:checked + label {
    @apply font-bold;
}

.scale-tick-item label:before {
    @apply bg-white;
    @apply border-2;
    @apply border-gray-300;
    @apply border-solid;
    @apply rounded-full;
    @apply w-6;
    @apply h-6;
    @apply absolute;

    content: "";
    font-size: 0;
    left: 0;
    top: 0;
    transition: all 125ms cubic-bezier(.96, .11, .71, 1);
}

.scale-tick-item input[type="radio"]:checked + label:before {
    @apply border-2;
    @apply border-primary-500;
    @apply w-6;
    @apply h-6;
    @apply bg-white;
}

.scale-tick-item label:after {
    @apply bg-primary-500;
    @apply rounded-full;
    @apply absolute;

    transition: all 125ms cubic-bezier(1, .71, .11, .96);
    content: "";
    width: 0;
    height: 0;
    left: 0.75rem;
    top: 0.75rem;
    transform: translate(25%, 25%);
}

.scale-tick-item input[type="radio"]:checked + label:after {
    @apply w-3;
    @apply h-3;
    left: 0;
    top: 0;
    transform: translate(50%, 50%);
}

@screen md {
    .scale-labels-min-max {
        @apply flex;
        @apply flex-row;
        @apply justify-between;
        @apply w-full;
        @apply font-thin;
    }

    .label-max {
        @apply block;
    }

    .label-min {
        padding-right: 0;
        padding-bottom: 0;
    }

    .label-max-phone {
        @apply hidden;
    }

    .scale-tick-item {
        @apply pt-7;
    }

    .scale-ticks {
        @apply w-full;
        @apply justify-between;
        @apply flex;
        @apply flex-row;
        @apply mt-6;
        @apply -mb-2;
        @apply pl-6;
        @apply pr-6;
    }

    .scale-tick-item {
        margin-top: 0px;
        padding-top: 0px;
    }

    .scale-tick-item label {
        @apply block;
        @apply text-center;
        @apply float-none;
        @apply w-auto;
        padding-bottom: 0px;
        margin-top: 0px;
        padding-top: 30px;
    }

    .scale-tick-item label:before:last-of-type {
        margin-right: 0;
    }
}

</style>
