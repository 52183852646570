<template>
    <section>
        <article>
            <h1 class="text-primary">Stap 3: Verlies van poep en windjes</h1>

            <p>Soms kunnen vrouwen hun poep of ontlasting niet ophouden na de bevalling. Of laten zonder dat ze
                het willen windjes. Heb jij daar ook weleens last van?
            </p>

            <YesNoInput :step="stepNr" :value.sync="fecesLoss" title="Verlies jij zo nu en dan poep of ontlasting?"
                        id="fecesLoss"/>

            <YesNoInput :step="stepNr" :value.sync="gasLoose"
                        title="Verlies jij weleens een windje, terwijl je dat niet wilt?" id="gasLoose"/>

            <div v-if="showFecesLossPanel">
                <p class="pt-6">Dank je wel dat je antwoord wilde geven op deze vragen.
                    Als je &lsquo;ja&rsquo; invulde bij de eerste of tweede vraag of bij allebei de vragen,
                    willen we graag meer van je weten.
                    We stellen je daarom een paar extra vragen over het verlies van poep zonder
                    dat je het wilt in de afgelopen vier weken.
                </p>
                <div v-if="showFecesLossQuestions">
                        <Slider :step="stepNr" :value.sync="rockSolidFeces"
                                title="Hoe vaak verlies je vaste poep?" id="rockSolidFeces"/>
                        <Slider :step="stepNr" :value.sync="unwantedFeces"
                                title="Hoe vaak verlies je dunne poep?" id="unwantedFeces"/>
                        <Slider :step="stepNr" :value.sync="usingAid"
                                title="Gebruik je speciaal verband om verlies van poep op te vangen?" id="usingAid"/>
                        <Scale  :step="stepNr" :value.sync="dailyObstruction" title="Heb je er last van in je dagelijks 
                                leven dat je poep verliest zonder dat je het wilt?" id="dailyObstruction"/>
                </div>
                <div v-if="showGasLooseQuestions">
                        <Slider :step="stepNr" :value.sync="losingGas" title="Hoe vaak laat je windjes zonder dat je het wilt?" id="losingGas"/>
                </div>
            </div>
        </article>
    </section>
</template>

<script>
import YesNoInput from '../FormElements/YesNoInput';
import Slider from '../FormElements/Slider';
import Scale from '../FormElements/Scale';

export default {
    data() {
        return {
            stepNr: 3,
            showFecesLossPanel: (this.$store.state.fecesLoss === 'Yes' || this.$store.state.gasLoose === 'Yes'),
            showFecesLossQuestions: (this.$store.state.fecesLoss === 'Yes'),
            showGasLooseQuestions: (this.$store.state.gasLoose === 'Yes')
        }
    },
    computed: {
        fecesLoss: {
            get: function () {
                return this.$store.state.fecesLoss;
            },
            set: function (value) {
                this.showFecesLossPanel = (value === 'Yes' || this.$store.state.gasLoose === 'Yes');
                this.showFecesLossQuestions = (value === 'Yes');
                this.$store.commit('storeFormValue', {name: 'fecesLoss', value: value});
            }
        },
        gasLoose: {
            get: function () {
                return this.$store.state.gasLoose;
            },
            set: function (value) {
                this.showFecesLossPanel = (value === 'Yes' || this.$store.state.fecesLoss === 'Yes');
                this.showGasLooseQuestions = (value === 'Yes');
                this.$store.commit('storeFormValue', {name: 'gasLoose', value: value});
            }
        },
        rockSolidFeces: {
            get: function () {
                return this.$store.state.rockSolidFeces;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'rockSolidFeces', value: value});
            }
        },
        unwantedFeces: {
            get: function () {
                return this.$store.state.unwantedFeces;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'unwantedFeces', value: value});
            }
        },
        losingGas: {
            get: function () {
                return this.$store.state.losingGas;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'losingGas', value: value});
            }
        },
        usingAid: {
            get: function () {
                return this.$store.state.usingAid;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'usingAid', value: value});
            }
        },
        dailyObstruction: {
            get: function () {
                return this.$store.state.dailyObstruction;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'dailyObstruction', value: value});
            }
        }

    },
    components: {
        YesNoInput,
        Slider,
        Scale
    }
}
</script>
