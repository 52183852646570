<template>
    <section>
        <article class="form-step">
            <h1>Stap 1: Jouw gegevens</h1>

            <p>Om je een advies te geven dat bij je past, willen we graag het volgende van je weten.</p>

            <NumberInput id="age" :value.sync="age" label="Hoe oud ben je?"
                         placeholder="Invullen in jaren, bijvoorbeeld: 34" :min="12" :max="70" unit="jaar"
                         :step="stepNr"/>
            <DateSelector id="dateOfBirth" :value.sync="dateOfBirth" label="Wanneer werd je (jongste) kind geboren?"
                          :step="stepNr"/>

            <AmountInput id="givenBirthAmount" :value.sync="givenBirthAmount" label="Hoe vaak ben je bevallen?"
                         :step="1" :min="stepNr"/>
            <AmountInput id="cesareanAmount" :value.sync="cesareanAmount" label="Bij hoeveel bevallingen kreeg je 
                         een geplande keizersnede? Dus hoe vaak kreeg je een snee in je buik om je kind(eren) te krijgen?"
                         :max="localGivenBirthAmount" :step="stepNr"/>

            <NumberInput id="length" :value.sync="length" label="Hoe lang ben je?"
                         placeholder="Invullen in cm, bijvoorbeeld: 180" :min="70" :max="250" unit="cm" :step="stepNr"/>
            <NumberInput id="weight" :value.sync="weight" label="Hoeveel kilo weeg je op dit moment?"
                         placeholder="Invullen in kilogram, bijvoorbeeld: 85" :min="35" :max="400" unit="kg"
                         :step="stepNr"/>
            <NumberInput id="weightBefore" :value.sync="weightBefore" label="Hoeveel kilo woog je toen je zwanger werd?"
                         placeholder="Invullen in kilogram, bijvoorbeeld: 80" :min="35" :max="400" unit="kg"
                         :step="stepNr"/>

        </article>
    </section>
</template>

<script>
import NumberInput from '../FormElements/NumberInput';
import AmountInput from '../FormElements/AmountInput';
import DateSelector from '../FormElements/DateSelector';

export default {
    data() {
        return {
            localGivenBirthAmount: 0,
            stepNr: 1
        }
    },
    computed: {
        age: {
            get: function () {
                return this.$store.state.age;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'age', value: value});
            }
        },
        dateOfBirth: {
            get: function () {
                return this.$store.state.dateOfBirth;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'dateOfBirth', value: value});
            }
        },
        givenBirthAmount: {
            get: function () {
                if (this.$store.state.givenBirthAmount) {
                    return this.$store.state.givenBirthAmount;
                }
                return this.localGivenBirthAmount;
            },
            set: function (value) {
                this.localGivenBirthAmount = value;
                this.$store.commit('storeFormValue', {name: 'givenBirthAmount', value: value});
            }
        },
        cesareanAmount: {
            get: function () {
                return this.$store.state.cesareanAmount;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'cesareanAmount', value: value});
            }
        },
        length: {
            get: function () {
                return this.$store.state.length;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'length', value: value});
            }
        },
        weight: {
            get: function () {
                return this.$store.state.weight;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'weight', value: value});
            }
        },
        weightBefore: {
            get: function () {
                return this.$store.state.weightBefore;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'weightBefore', value: value});
            }
        }
    },
    components: {
        NumberInput,
        AmountInput,
        DateSelector
    }
}
</script>

<style>
h1 {
    @apply mt-6;
    @apply leading-none;
    @apply mb-4;
}
</style>
