export default {
    namespaced: true,
    state: {
        registeredFormComponents: {},
        steps: [],
        formLastUpdated: (new Date()).getTime()
    },
    mutations: {
        registerFormComponent(state, payload) {
            state.registeredFormComponents[payload.parent] = {...state.registeredFormComponents[payload.parent], ...{[payload.owner]: payload}}
            state.formLastUpdated = (new Date()).getTime();
        },
        unRegisterFormComponent(state, payload) {
            delete state.registeredFormComponents[payload.parent][payload.owner];
            state.formLastUpdated = (new Date()).getTime();
        },
        updateFormComponent(state, payload) {
            state.registeredFormComponents[payload.parent][payload.owner] = payload;
            state.formLastUpdated = (new Date()).getTime();
        },
        setStepValidity(state, payload) {
            state.steps[payload.stepNr] = payload.isValid;
        }
    },
    actions: {
        actionValidateStep: ({state, commit}, payload) => {
            let inValidComponents = [];

            if (typeof state.registeredFormComponents[payload.stepNr] !== 'undefined') {
                const validationComponents = Object.keys(state.registeredFormComponents[payload.stepNr]);
                for (const validationComponent of validationComponents) {
                    if (!state.registeredFormComponents[payload.stepNr][validationComponent].isValid) {
                        inValidComponents.push(validationComponent);

                    }
                }
            }

            commit('setStepValidity', {stepNr: payload.stepNr, isValid: (inValidComponents.length <= 0)});
        }
    }
}
