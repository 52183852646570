<template>
    <div class="form-element text-input-element" v-bind:class="{'border-alert': errorMessages.currentMessage}">
        <div class="question" v-html="title"></div>
        <div class="slider-input-group">
            <div class="slider-ticks">
                    <span v-for="(item, i) in frequencies" :key="i" class="slider-tick-item">
                        <input v-model="localModel" type="radio" :name="id" :id="id + item.id" :value="item.value">
                        <label :for="id + item.id">
                            {{ item.label }}
                            <span v-if="item.description" v-html="item.description" class="item-description"></span>
                        </label>
                    </span>
            </div>
        </div>
        <p v-if="errorMessages.currentMessage" class="error-message">{{ errorMessages.currentMessage }}</p>
    </div>
</template>

<script>
import {validationMixin} from "../mixins/ValidationMixin";

export default {
    props: {
        'title': String,
        'id': String,
        'value': String,
        'step': Number
    },
    data: function () {
        return {
            internalValue: String,
            frequencies: [
                {value: 'never', id: 'Never', label: 'Nooit'},
                {value: 'rare', id: 'Rare', label: 'Bijna nooit', description: 'minder dan &eacute;&eacute;n keer per maand'},
                {value: 'sometimes', id: 'Sometimes', label: 'Soms', description: 'minder dan &eacute;&eacute;n keer per week'},
                {value: 'often', id: 'Often', label: 'Vaak', description: '&eacute;&eacute;n keer per week of meer'},
                {value: 'always', id: 'Always', label: 'Altijd', description: 'dagelijks'}
            ],
            errorMessages: {
                currentMessage: '',
                messages: [
                    'Je moet hier nog een keuze opgeven.'
                ]
            }
        }
    },
    created() {
        this.internalValue = this.value ? this.value : '';
        this.validateComponent();
    },
    computed: {
        localModel: {
            get: function () {
                return this.internalValue;
            },
            set: function (value) {
                this.internalValue = value;
                this.validationComponent.isDirty = true;
                this.$emit('update:value', this.internalValue);
                this.validateComponent();
            }
        }
    },
    mixins: [validationMixin]
}
</script>

<style>
.slider-input-group {
    @apply border-2;
    @apply border-solid;
    @apply rounded;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply mt-2;
    @apply pt-3;
    @apply pb-3;
    @apply px-3;
}

.slider-ticks {
    @apply justify-between;
    @apply mt-1;
    @apply flex;
    @apply flex-col;
    @apply w-full;
}

.slider-ticks input[type="radio"] {
    @apply hidden;
}

.slider-ticks .slider-tick-label span {
    @apply whitespace-no-wrap;
    @apply mt-4;
    @apply text-gray-700;
    @apply text-center;
    @apply block;
    @apply relative;
    font-size: 0.8rem;
}

.slider-tick-item {
    @apply mx-auto;
    @apply items-center;
    @apply relative;
    @apply flex;
    @apply flex-row;
    @apply w-full;
    margin-bottom: 10px;
}

.slider-tick-item span {
    @apply text-sm;
}

.slider-tick-item label {
    padding-left: 35px;
}

.slider-tick-item label {
    cursor: pointer;
}

.slider-tick-item input[type="radio"]:checked + label {
    @apply font-bold;
}

.slider-tick-item label:before {
    @apply bg-white;
    @apply border-2;
    @apply border-gray-300;
    @apply border-solid;
    @apply rounded-full;
    @apply w-6;
    @apply h-6;
    @apply absolute;

    content: "";
    font-size: 0;
    left: 0;
    top: 0;
    transition: all 125ms cubic-bezier(.96, .11, .71, 1);
}

.slider-tick-item input[type="radio"]:checked + label:before {
    @apply border-2;
    @apply border-primary-500;
    @apply w-6;
    @apply h-6;
    @apply bg-white;

}

.slider-tick-item label:after {
    @apply bg-primary-500;
    @apply rounded-full;
    @apply absolute;
    transition: all 125ms cubic-bezier(1, .71, .11, .96);
    content: "";
    width: 0;
    height: 0;
    left: 0.75rem;
    top: 0.75rem;
    transform: translate(25%, 25%);
}

.slider-tick-item input[type="radio"]:checked + label:after {
    @apply w-3;
    @apply h-3;
    left: 0;
    top: 0;
    transform: translate(50%, 50%);

}

.item-description {
    @apply block;
}

.item-description br {
    @apply hidden;
}

@screen md {
    .slider-ticks {
        @apply flex;
        @apply flex-row;
    }

    .slider-tick-item label {
        padding-left: 0px;
        padding-top: 45px;
        @apply mx-auto;
    }

    .slider-tick-item label:first-of-type {
        padding-left: 0px;
        padding-top: 40px;
    }

    .slider-tick-item {
        @apply text-center;
        @apply items-baseline;
        padding-left: 5px;
    }

    .slider-tick-item label:after {
        left: 50%;
        transform: translate(-50%, 0)
    }

    .slider-tick-item label:before {
        left: 50%;
        transform: translate(-50%, 0)
    }

    .slider-tick-item input[type="radio"]:checked + label:after {
        left: 50%;
        transform: translate(-50%, 50%)
    }
}
</style>
