<template>
    <div class="form-element text-input-element" v-bind:class="{'border-alert': errorMessages.currentMessage}">
        <label :for="id" v-if="label" class="question" v-html="label"></label>
        <input class="answer" v-model.lazy="model" :id="id" type="text" v-bind:placeholder="placeholder">
        <p v-if="errorMessages.currentMessage" class="error-message">{{ errorMessages.currentMessage }}</p>
    </div>
</template>

<script>
import {validationMixin} from '../mixins/ValidationMixin';

export default {
    props: {
        label: String,
        id: String,
        value: Number,
        placeholder: String,
        min: {type: Number, default: -1},
        max: {type: Number, default: -1},
        unit: String,
        step: Number
    },
    data() {
        return {
            internalValue: (typeof this.value !== 'undefined') ? this.value : 0,
            errorMessages: {
                currentMessage: '',
                messages: [
                    'De opgegeven waarde moet tussen de ' + this.min + ' ' + this.unit + ' en ' + this.max + ' ' + this.unit + ' liggen'
                ]
            }
        }
    },
    created() {
        this.validationComponent.isDirty = !!(this.value);
        this.validateComponent();
    },
    methods: {
        isValueValid: function () {
            let returnValue = false;

            if ((this.min > -1 && this.internalValue >= this.min) && (this.max > -1 && this.internalValue <= this.max)) {
                returnValue = true;
            }

            if (this.min === -1 && this.max === -1 && this.internalValue >= 0) {
                returnValue = true;
            }

            return returnValue;
        }
    },
    computed: {
        model: {
            get: function () {
                return (this.internalValue ? this.internalValue + " " + this.unit : '');
            },
            set: function (value) {
                this.internalValue = parseInt(value.replace(/\D/g, ''));
                this.validationComponent.isDirty = true;
                this.$emit('update:value', this.internalValue);
                this.validateComponent();
            }
        }
    },
    mixins: [validationMixin]
}
</script>

<style>
.text-input-element input[type=text] {
    @apply appearance-none;
    @apply block;
    @apply w-full;
    @apply bg-gray-200;
    @apply text-gray-700;
    @apply border;
    @apply rounded;
    @apply py-3;
    @apply px-4;
    @apply mb-3;
    @apply leading-tight;
}

.text-input-element input[type=text]:focus {
    @apply outline-none;
    @apply bg-white;
}

.question {
    @apply text-black;
    @apply text-sm;
    @apply font-semibold;
    @apply mt-4;
    @apply pb-2;
    @apply leading-tight;
}

.form-element {
    @apply mt-6;
    @apply relative
}

.answer {
    @apply mt-2;
}
</style>
