<template>
    <section>
        <article>
            <h1>Stap 6: Bevalling en zwangerschap</h1>

            <p>De manier waarop je bent bevallen en hoe het gaat met je 
                zwangerschap geeft je soms een grotere kans op 
                bekken(bodem)klachten. Kijk of dat ook voor jou geldt. Let op! 
                Houd daarbij niet alleen rekening met je laatste bevalling of
                zwangerschap als je er meerdere hebt gehad. Sommige klachten
                kunnen ook van eerdere zwangerschappen en bevallingen komen. 
            </p>

            <YesNoInput :step="stepNr" :value.sync="vacuumBirth" title="Moest je bevallen met een tang of vacu&uuml;mpomp (een pomp waarbij je kindje wordt
                        gehaald met een zuignap op het hoofdje)?" id="vacuumBirth"/>
            <YesNoInput :step="stepNr" :value.sync="heavyBaby"
                        title="Woog minstens &eacute;&eacute;n van je kinderen bij de geboorte 4000 gram of meer?" id="heavyBaby"/>
            <YesNoInput :step="stepNr" :value.sync="rupture"
                        title="Scheurde je gedeeltelijk of geheel uit tijdens de bevalling, waardoor je gehecht moest worden op de operatiekamer?" 
                        id="rupture"/>
            <YesNoInput :step="stepNr" :value.sync="secondHalfUrineLoss"
                        title="Verloor je zonder dat je dat wilde plas of urine in de tweede helft van je zwangerschap?"
                        id="secondHalfUrineLoss"/>
            <YesNoInput :step="stepNr" :value.sync="unwantedFecesPregnancy"
                        title="Verloor je zonder dat je het wilde poep of ontlasting tijdens je zwangerschap?"
                        id="unwantedFecesPregnancy"/>
            <YesNoInput :step="stepNr" :value.sync="olderDelivery" title="Was je vijfendertig jaar of ouder toen je beviel?"
                        id="olderDelivery"/>
            <YesNoInput :step="stepNr" :value.sync="prePregnancyPain"
                        title="Had je voordat je zwanger werd pijn in je onderrug of in je bekken?"
                        id="prePregnancyPain"/>
            <YesNoInput :step="stepNr" :value.sync="pregnancyPelvisPain"
                        title="Deed je bekken pijn tijdens de zwangerschap?" id="pregnancyPelvisPain"/>
            <YesNoInput :step="stepNr" :value.sync="blockageNow"
                        title="Heb je last van verstopping? Kun je moeilijk naar de wc?" id="blockageNow"/>
            <YesNoInput :step="stepNr" :value.sync="preludeLabor"
                        title="Was de bevalling ingeleid?" id="preludeLabor"/>
        </article>
    </section>
</template>

<script>
import YesNoInput from '../FormElements/YesNoInput';

export default {
    data() {
        return {
            stepNr: 6
        }
    },
    computed: {
        vacuumBirth: {
            get: function () {
                return this.$store.state.vacuumBirth;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'vacuumBirth', value: value});
            }
        },
        heavyBaby: {
            get: function () {
                return this.$store.state.heavyBaby;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'heavyBaby', value: value});
            }
        },
        rupture: {
            get: function () {
                return this.$store.state.rupture;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'rupture', value: value});
            }
        },
        secondHalfUrineLoss: {
            get: function () {
                return this.$store.state.secondHalfUrineLoss;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'secondHalfUrineLoss', value: value});
            }
        },
        unwantedFecesPregnancy: {
            get: function () {
                return this.$store.state.unwantedFecesPregnancy;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'unwantedFecesPregnancy', value: value});
            }
        },
        olderDelivery: {
            get: function () {
                return this.$store.state.olderDelivery;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'olderDelivery', value: value});
            }
        },
        prePregnancyPain: {
            get: function () {
                return this.$store.state.prePregnancyPain;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'prePregnancyPain', value: value});
            }
        },
        pregnancyPelvisPain: {
            get: function () {
                return this.$store.state.pregnancyPelvisPain;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'pregnancyPelvisPain', value: value});
            }
        },
        blockageNow: {
            get: function () {
                return this.$store.state.blockageNow;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'blockageNow', value: value});
            }
        },
        preludeLabor: {
            get: function () {
                return this.$store.state.preludeLabor;
            },
            set: function (value) {
                this.$store.commit('storeFormValue', {name: 'preludeLabor', value: value});
            }
        }
    },
    components: {
        YesNoInput
    }
}
</script>
