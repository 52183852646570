<template>
    <div class="form-element amount-input-element" v-bind:class="{'border-alert': errorMessages.currentMessage}">
        <label :for="id" v-if="label" class="question" v-html="label"></label>
        <div class="amount-input-section">
            <button class="decrease-button" :class="[canDecreaseAmount() ? '': 'opacity-50']"
                    v-on:click="decreaseAmount"></button>
            <span class="text-xl leading-small px-3">{{ internalValue }}</span>
            <button class="increase-button" :class="[canIncreaseAmount() ? '': 'opacity-50']"
                    v-on:click="increaseAmount"></button>
            <label class="text-xl leading-small pl-2">Keer</label>
        </div>
        <p v-if="errorMessages.currentMessage" class="error-message">{{ errorMessages.currentMessage }}</p>
    </div>
</template>

<script>
import {validationMixin} from '../mixins/ValidationMixin';

export default {
    props: {
        label: String,
        value: Number,
        id: String,
        max: {
            type: Number,
            default: -1
        },
        min: {
            type: Number,
            default: -1
        },
        step: Number
    },
    data() {
        return {
            internalValue: (typeof this.value !== 'undefined') ? this.value : 0,
            errorMessages: {
                currentMessage: '',
                messages: [
                    'Je moet hier nog een geldige waarde opgeven.'
                ]
            }
        }
    },
    created() {
        this.validationComponent.isDirty = !!(this.value);
        this.validateComponent();
    },
    watch: {
        min: function () {
            this.validateComponent();
        },
        max: function () {
            if (this.max < this.internalValue) {
                this.internalValue = this.max;
                this.storeValue();
            }
            this.validateComponent();
        }
    },
    methods: {
        increaseAmount: function () {
            if (this.canIncreaseAmount()) {
                this.internalValue++;
                this.storeValue();
            }
        },
        decreaseAmount: function () {
            if (this.canDecreaseAmount()) {
                this.internalValue--;
                this.storeValue();
            }
        },
        canIncreaseAmount: function () {
            return ((this.max > -1 && this.internalValue < this.max) || this.max === -1);
        },
        canDecreaseAmount: function () {
            return (this.min > -1 && this.internalValue > this.min) || (this.min === -1 && this.internalValue > 0);
        },
        storeValue() {
            this.validationComponent.isDirty = true;
            this.$emit('update:value', this.internalValue);
            this.validateComponent();
        },
        isValueValid: function () {
            let returnValue = false;

            if ((this.min > -1 && this.internalValue >= this.min) && (this.max > -1 && this.internalValue <= this.max)) {
                returnValue = true;
            }

            if ((this.min === -1 && this.max > -1 && this.internalValue <= this.max) || (this.max === -1 && this.min > -1 && this.internalValue >= this.min)) {
                returnValue = true;
            }

            if (this.min === -1 && this.max === -1 && this.internalValue >= 0) {
                returnValue = true;
            }
            return returnValue;
        }
    },
    computed: {
        model: {
            get: function () {
                return this.internalValue;
            }
        }
    },
    mixins: [validationMixin]
}
</script>

<style>

button {
    border: 2px solid green;
}

.amount-input-section {
    @apply border-2;
    @apply border-solid;
    @apply rounded;
    @apply flex;
    @apply items-center;
    @apply mt-2;
    @apply pt-3;
    @apply pb-3;
    @apply px-3;
}

.increase-button,
.decrease-button {
    background-size: 85%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    @apply appearance-none;
    @apply m-0;
    @apply p-0;
    @apply w-8;
    @apply h-8;
    @apply leading-none;
    @apply border-none;
    @apply bg-transparent;
    @apply cursor-pointer;
}

.increase-button {
    @apply mx-2;
    background-image: url('../../../src/assets/images/icons/increase.svg');
}

.decrease-button {
    @apply mr-2;
    background-image: url('../../../src/assets/images/icons/decrease.svg');
}
</style>
