<template>
    <div class="form-element date-selector-input-element" v-bind:class="{'border-alert': errorMessages.currentMessage}">
        <label :for="id" v-if="label" class="question" v-html="label"></label>

        <div class="birthday-selector w-1/2 -sm:max-w-85.75 sm-:w-85.75">
            <div class="selector date-of-birth-day">
                <select id="dateOfBirthDay" v-model="day">
                    <option value="0">Dag</option>
                    <option v-for="day in 31" :key="day" :value="day" :selected="day === 1">
                        {{ day }}
                    </option>
                </select>
            </div>

            <div class="selector date-of-birth-month">
                <select id="dateOfBirthMonth" v-model="month">
                    <option value="0">Maand</option>
                    <option v-for="month in months" :key="month.numeric" :value="month.numeric">
                        {{ month.textual }}
                    </option>
                </select>
            </div>

            <div class="selector date-of-birth-year">
                <select id="dateOfBirthYear" v-model="year">
                    <option value="0">Jaar</option>
                    <option v-for="(year, index) in years" :key="index" :value="year">{{ year }}</option>
                </select>
            </div>
        </div>
        <p v-if="errorMessages.currentMessage" class="error-message">{{ errorMessages.currentMessage }}</p>
    </div>
</template>

<script>
import moment from 'moment';
import {validationMixin} from '../mixins/ValidationMixin';

export default {
    props: {
        label: String,
        value: Object,
        id: String,
        step: Number
    },
    data: function () {
        return {
            errorMessages: {
                currentMessage: '',
                messages: [
                    'Dit onderzoek is bedoeld voor bevallingen in de afgelopen negen maanden.',
                    'Je moet een datum opgeven die in het verleden ligt',
                    'De ingevoerde datum klopt niet, voer een bestaande datum in'
                ]
            },
            months: [
                {textual: 'januari', numeric: 1},
                {textual: 'februari', numeric: 2},
                {textual: 'maart', numeric: 3},
                {textual: 'april', numeric: 4},
                {textual: 'mei', numeric: 5},
                {textual: 'juni', numeric: 6},
                {textual: 'juli', numeric: 7},
                {textual: 'augustus', numeric: 8},
                {textual: 'september', numeric: 9},
                {textual: 'oktober', numeric: 10},
                {textual: 'november', numeric: 11},
                {textual: 'december', numeric: 12}
            ],
            years: [],
            maximumYears: 6,
            maximumAgeInWeeks: 39,
            dateOfBirth: {
                day: 0,
                month: 0,
                year: 0,
                weeks: 0
            }
        }
    },
    created() {
        this.dateOfBirth = (typeof this.value !== 'undefined' ? this.value : this.dateOfBirth);
        this.validationComponent = {owner: this.id, isDirty: false, parent: this.step};
        this.$store.commit('validation/registerFormComponent', this.validationComponent);
        this.validateComponent();
    },
    mounted: function () {
        this.$nextTick(function () {
            this.populateYears();
        })
    },
    methods: {
        populateYears: function () {
            this.years = [];
            let now = new Date();
            let currentYear = now.getFullYear();
            let minimumYear = currentYear - this.maximumYears;
            for (let i = minimumYear; i <= currentYear; i++) {
                this.years.push(i);
            }
        },
        createMomentDateOfBirthObject() {
            return moment(this.dateOfBirth.year + "-" + String(this.dateOfBirth.month).padStart(2, '0') + "-" + String(this.dateOfBirth.day).padStart(2, '0'));
        },
        isDateComplete: function () {
            return (parseInt(this.dateOfBirth.day) > 0 && parseInt(this.dateOfBirth.month) > 0 && parseInt(this.dateOfBirth.year) > 0);
        },
        isValueValid: function () {
            let returnValue = false;

            if (this.isDateComplete()) {
                let parsedDateOfBirth = this.createMomentDateOfBirthObject();
                if (parsedDateOfBirth.isValid()) {
                    if (parsedDateOfBirth.isBefore(new Date())) {
                        returnValue = true;
                    } else {
                        this.errorMessages.currentMessage = this.errorMessages.messages[1];
                    }
                } else {
                    this.errorMessages.currentMessage = this.errorMessages.messages[2];
                }
            }
            return returnValue;
        },
        validateComponent: function () {
            this.validationComponent.isValid = false;

            if (this.isValueValid()) {
                this.errorMessages.currentMessage = '';
                this.validationComponent.isValid = true;
            }

            this.$store.commit('validation/updateFormComponent', this.validationComponent);

            if (this.isDateComplete()) {
                this.storeDateOfBirth();
            }
        },
        storeDateOfBirth() {
            let parsedDateOfBirth = this.createMomentDateOfBirthObject();
            this.dateOfBirth.weeks = moment().diff(parsedDateOfBirth, 'week');
            this.validationComponent.isDirty = true;
            this.$emit('update:value', this.dateOfBirth);
            this.$store.commit('validation/updateFormComponent', this.validationComponent);
        }
    },
    computed: {
        day: {
            get: function () {
                return (this.value ? this.value.day : this.dateOfBirth.day);
            },
            set: function (value) {
                this.dateOfBirth.day = value;
                this.validationComponent.isDirty = true;
                this.validateComponent();
            }
        },
        month: {
            get: function () {
                return (this.value ? this.value.month : this.dateOfBirth.month);
            },
            set: function (value) {
                this.dateOfBirth.month = value;
                this.validationComponent.isDirty = true;
                this.validateComponent();
            }
        },
        year: {
            get: function () {
                return (this.value ? this.value.year : this.dateOfBirth.year);
            },
            set: function (value) {
                this.dateOfBirth.year = value;
                this.validationComponent.isDirty = true;
                this.validateComponent();
            }
        }
    },
    mixins: [validationMixin]
}
</script>

<style>
.birthday-selector {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply flex-wrap;
    @apply my-1;
}

.birthday-selector .selector {
    @apply relative;
    @apply bg-primary-500;
    @apply rounded;
    @apply text-white;
    @apply h-12;
}

.birthday-selector .selector::after {
    @apply absolute;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    bottom: 0;
    content: '';
    height: 0;
    margin: auto;
    pointer-events: none;
    right: 10px;
    top: 0;
    width: 0;
}

.birthday-selector option {
    @apply text-primary-500;
}

.birthday-selector .selector:nth-child(2) {
    @apply my-1;
}

.birthday-selector .selector select {
    @apply h-full;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    outline: none;
    padding: 0 1.75rem 0 .75rem;
    width: 100%;
}

.birthday-selector .selector select::-ms-expand {
    @apply hidden;
}


@screen sm {
    .birthday-selector {
        @apply flex-row;
    }

    .date-of-birth-month {
        max-width: 200px;
    }

    .birthday-selector .selector:nth-child(1),
    .birthday-selector .selector:nth-child(3) {
        flex: 0 0 80px;
    }

    .birthday-selector .selector:nth-child(2) {
        @apply mx-1;
        @apply my-0;
        flex: 1;
    }
}
</style>
