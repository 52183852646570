<template>
    <div class="risk-factors-container" v-if="hasActiveRiskFactor()">
        <h3 v-html="label"></h3>
        <ul>
            <li v-for="(item, index) in items" :key="index">
                <span v-if="item.condition">
                    <span v-if="item.riskfactor == 'red'" class="risk-factor-icon-container"><i class="risk-factor-red"></i><i
                    class="risk-factor-red"></i><i class="risk-factor-red"></i></span>
                    <span v-if="item.riskfactor == 'orange'" class="risk-factor-icon-container"><i
                        class="risk-factor-orange"></i><i class="risk-factor-orange"></i></span>
                    <span v-if="item.riskfactor == 'green'" class="risk-factor-icon-container"><i
                        class="risk-factor-green"></i></span>
                    <span class="risk-factor-message">{{ item.message }}</span>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['label', 'items', 'id'],
    created: function () {
        this.$emit('registerRiskFactorGroup', (this.hasActiveRiskFactor()) ? this.id : false);
    },
    methods: {
        hasActiveRiskFactor: function () {
            return this.items.find(function (item) {
                return item.condition === true;
            });
        }
    }
}
</script>

<style>
.risk-factors-container li {
    @apply relative;
    @apply leading-loose;
}

.risk-factors-container h3 {
    @apply mb-4;
    @apply mt-8;
    @apply text-base;
}

.risk-factor-icon-container {
    @apply absolute;
    @apply text-right;
    @apply w-16;
    @apply pt-1;
}

.risk-factor-message {
    @apply ml-20;
    @apply mb-4;
    @apply block;
}

.risk-factor-red,
.risk-factor-orange,
.risk-factor-green {
    @apply inline-block;
    background-size: 85%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    @apply appearance-none;
    @apply m-0;
    @apply p-0;
    @apply w-5;
    @apply h-5;
    @apply leading-none;
    @apply border-none;
    @apply bg-transparent;
    @apply cursor-pointer;
}

.risk-factor-red {
    background-image: url('../../../src/assets/images/icons/risk-factor-red.svg');
}

.risk-factor-orange {
    background-image: url('../../../src/assets/images/icons/risk-factor-orange.svg');
}

.risk-factor-green {
    background-image: url('../../../src/assets/images/icons/risk-factor-green.svg');
}

h3 {
    @apply font-semibold;
}
</style>
